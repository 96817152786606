import Cookies from 'js-cookie';

import { Cookie } from '../network/enums';
import { TCartItem } from '../network/types';
import { AnalyticsItem, EventCategory, EventName, FavouritesEventSource, useAnalytics } from './useAnalytics';

const mapAnalyticsItems = (items: TCartItem[]): AnalyticsItem[] =>
  items.map((item) => ({
    id: item.productNumber,
    quantity: parseInt(item.quantity as string, 10),
    price: item.productPrice?.toString(),
  }));

const totalItemValue = (items: TCartItem[]): string =>
  items.reduce((sum, item) => sum + item.productPrice, 0).toString();

export const useCartAnalytics = () => {
  const { sendEvent, sendErrorEvent } = useAnalytics();

  const sendAddToCartEvent = (items: TCartItem[], source: string) => {
    const custom: any = { cart_id: Cookies.get(Cookie.MV_CART_ID) };
    if (!Object.values(FavouritesEventSource).includes(source as FavouritesEventSource)) {
      custom.cart_value = totalItemValue(items);
    }
    const currency = items[0]?.currency || '';
    sendEvent({
      name: EventName.ADD_TO_CART,
      event_label: source,
      currency,
      params: {
        items: mapAnalyticsItems(items),
      },
      custom,
    });
  };

  const sendCartMergeConflictEvent = (quantity: string) => {
    const cartId = Cookies.get(Cookie.MV_CART_ID);
    sendEvent({
      event_action: EventName.CART_MERGE_CONFLICT,
      event_category: EventCategory.CART,
      event_label: quantity,
      custom: {
        cart_id: cartId,
      },
      non_interaction: false,
    });
  };

  return { sendErrorEvent, sendAddToCartEvent, sendCartMergeConflictEvent };
};
