import { AxiosError } from 'axios';
import { profileAxiosInstance as network } from '../network/axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Endpoints, SiteTypes } from '../network/enums';
import { endpointReplace } from '../network/utils/endpointReplace';
import { useUpdateBroadcastChannel } from '@mvecom/common/hooks/useUpdateBroadcastChannel';
import { Actions } from '../actions/enums';

export type LoginEvent =
  | 'login-started'
  | 'logged-out'
  | 'initial-auth-check-false'
  | 'login-failed'
  | 'login-success'
  | 'validation-failure';

export const useLogin = (isModalOpen: boolean, onChange?: (event: LoginEvent) => void) => {
  const [loggedIn, setLoggedIn] = useState<boolean>();
  const [authError, setAuthError] = useState<AxiosError>();
  const [loginError, setLoginError] = useState<AxiosError>();
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  // listen to iframe post messages
  useEffect(() => {
    const ev = (event: MessageEvent) => {
      const pubsubStart = 'pubsub::';

      if (event.data === pubsubStart + Actions.USER_LOGGED_OUT) {
        setLoggedIn(false);
        onChange?.('logged-out');
      }

      // mostly for completion, shouldn't really happen
      if (event.data === pubsubStart + Actions.USER_LOGGED_IN) {
        setLoggedIn(true);
        onChange?.('login-success');
      }
    };

    window.addEventListener('message', ev);
    return () => window.removeEventListener('message', ev);
  }, [onChange]);

  // listen to other tabs updating
  const broadcast = useUpdateBroadcastChannel('profile_pages_update', (message) => {
    if (message.type === 'profile') {
      if (message.action === 'signed-in') {
        setLoggedIn(true);
        onChange?.('login-success');
      } else if (message.action === 'signed-out') {
        setLoggedIn(false);
        onChange?.('logged-out');
      }
    }
  });

  // check login on start up
  useEffect(() => {
    if (!isModalOpen) {
      setLoginError(undefined);
      setLoginLoading(false);
      return;
    }

    let aborted = false;
    const controller = new AbortController();

    network
      .get(endpointReplace({ ':siteType': SiteTypes.PROFILE }, Endpoints.Auth), {
        signal: controller.signal,
      })
      .then((isLoggedInData) => {
        if (!aborted) {
          const isLoggedIn = !!isLoggedInData.data;

          if (isLoggedIn) {
            window?.ikea?.pubsub?.publish?.(Actions.USER_INFO_AVAILABLE);
            setLoggedIn(true);
          } else {
            onChange?.('initial-auth-check-false');
            setLoggedIn(false);
          }
        }
      })
      .catch((e) => {
        setAuthError(e);
        setLoginLoading(false);
      });

    return () => {
      aborted = true;
      controller.abort();
    };
  }, [isModalOpen, onChange]);

  // login using email and password, send all the events
  const login = useCallback(
    (email: string, password: string) => {
      setLoginLoading(true);
      onChange?.('login-started');
      network
        .post(endpointReplace({ ':siteType': SiteTypes.PROFILE }, Endpoints.Auth), {
          password,
          userName: email,
        })
        .then(({ status, data }) => {
          const loggedIn = status >= 200 && status <= 202;
          setLoggedIn(loggedIn);

          if (loggedIn) {
            broadcast({
              type: 'profile',
              action: 'signed-in',
            });
            window?.ikea?.pubsub?.publish?.(Actions.USER_LOGGED_IN);
            onChange?.('login-success');
          }

          window?.sendEvent?.({
            custom: {
              user_id: data.userId,
            },
            event_action: 'logins_regular_profile',
            event_category: 'user_actions',
            event_label: loggedIn ? 'success' : 'fail',
            non_interaction: false,
          });

          if (data?.validationFailures) {
            onChange?.('validation-failure');
          }
        })
        .catch((e) => {
          onChange?.('login-failed');
          setLoginLoading(false);
          setLoginError(e);
        })
        .finally(() => setLoginLoading(false));
    },
    [broadcast, onChange],
  );

  return useMemo(
    () => ({
      login,
      loggedIn: !!loggedIn,
      loadingLoggedIn: loggedIn === undefined,
      loginLoading,
      loginError,
      authError,
    }),
    [authError, loggedIn, login, loginError, loginLoading],
  );
};
