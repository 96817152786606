import { useCallback, useEffect, useMemo, useState } from 'react';
import { stringToBoolean } from '@mvecom/common/utils/stringToBoolean';
import { useTranslation } from 'react-i18next';

import { EventName } from '../../hooks/useAnalytics';
import {
  TAddToFavouritesPayloadResponse,
  TAddToFavouritesPayloadResponseError,
  TRemoveFromFavouritesPayloadResponse,
  TRemoveFromFavouritesPayloadResponseError,
  useFavourites,
} from '../../hooks/useFavourites';
import { useFavouritesAnalytics } from '../../hooks/useFavouritesAnalytics';
import { createList } from '../../network/endpoints/favourites/createList';
import { deleteItemFromList } from '../../network/endpoints/favourites/deleteItemFromList';
import { postItem } from '../../network/endpoints/favourites/postItem';
import { AddOrRemoveItem } from '../Modals/AddOrRemoveItemModal/AddOrRemoveItemModal';
import { AddToFavouritesModal, MoveItemsModalStatus } from '../Modals/AddToFavouritesModal/AddToFavouritesModal';
import { CreateListAndAddToFavouritesModal } from '../Modals/CreateListAndAddToFavouritesModal/CreateListAndAddToFavouritesModal';
import { RemoveFromFavouritesModal } from '../Modals/RemoveFromFavouritesModal/RemoveFromFavouritesModal';
import { RemovedListsModal } from '../Modals/RemovedListsModal/RemovedListsModal';
import { StorageState } from '../../actions/enums';
import { splitPath } from '@mvecom/common/utils/splitPath';
import { goToFavourites } from '../../network/utils/goToFavourites';

const MAX_LISTS_LIMIT = 10;

type ListToMap = {
  id: string;
  name: string;
  image: {
    altText?: string;
    src?: string;
    srcSet?: string;
    fallback: boolean;
  };
  itemIds?: string[];
};

export const MvAgentFavourites = () => {
  const {
    itemToAdd,
    listsToAddItem,
    showAddItemToListModal,
    setShowAddItemToListModal,
    addToFavouritesSuccess,
    addToFavouritesFail,
    itemToRemove,
    listsToRemoveItem,
    removedLists,
    setShowRemovedListsModal,
    showAddOrRemoveModal,
    showRemovedListsModal,
    setShowAddOrRemoveModal,
    removeFromFavouritesSuccess,
    removeFromFavouritesFail,
    removeFromList,
    getLists,
  } = useFavourites();

  const { t } = useTranslation('translations');

  const { sendErrorEvent, sendCreateListEvent } = useFavouritesAnalytics();
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [showRemoveItemModal, setShowRemoveItemModal] = useState(false);
  const [showFavouritesMergeExceededMaxAmountModal, setShowFavouritesMergeExceededMaxAmountModal] = useState(false);

  const onRemoveItem = useCallback(async () => {
    setShowAddOrRemoveModal(false);
    try {
      const lists = await getLists();
      const listsWithItem = lists.filter(
        (list) =>
          list.items &&
          list.items.some(
            (item) => itemToRemove?.productNumber === item.number && itemToRemove?.productType === item.type,
          ),
      );
      if (listsWithItem.length === 1 && itemToRemove) {
        return removeFromList(itemToRemove, listsWithItem[0]);
      }
    } catch (e) {
      if (itemToRemove) {
        removeFromFavouritesFail(e, itemToRemove);
      }
    }
    setShowRemoveItemModal(true);
  }, [getLists, itemToRemove, removeFromFavouritesFail, removeFromList, setShowAddOrRemoveModal]);

  const onFavourites = useMemo(() => {
    const appName = splitPath(window.location.pathname)[2];
    return appName === 'favourites';
  }, []);

  useEffect(() => {
    if (
      stringToBoolean(sessionStorage.getItem(StorageState.SHOW_FAVOURITES_MERGE_EXCEEDED_MAX_AMOUNT_PROMPT)) &&
      onFavourites
    ) {
      sessionStorage.removeItem(StorageState.SHOW_FAVOURITES_MERGE_EXCEEDED_MAX_AMOUNT_PROMPT);
      setShowFavouritesMergeExceededMaxAmountModal(true);
    }
  }, [onFavourites, showRemovedListsModal]);

  const getMappedLists = (lists: ListToMap[]) =>
    lists.map((list: ListToMap) => ({
      id: list.id,
      title: list.name,
      image: {
        alt: list.image?.altText,
        src: list.image?.src,
        srcSet: list.image?.srcSet,
        fallback: !list.image,
      },
    }));

  const canceledByUserError = new Error('Canceled by user');

  const addItemToList = useCallback(
    async (selectedList: string): Promise<string> => {
      if (!itemToAdd) throw new Error('item not found');
      const response = await postItem(selectedList, {
        itemNumber: itemToAdd.productNumber,
        itemType: itemToAdd.productType,
        quantity: parseInt(itemToAdd.quantity as string, 10),
      });
      return response.results.item.id;
    },
    [itemToAdd],
  );

  return (
    <>
      <AddToFavouritesModal
        isOpen={showAddItemToListModal}
        choices={getMappedLists(listsToAddItem as ListToMap[])}
        status={listsToAddItem.length === MAX_LISTS_LIMIT ? MoveItemsModalStatus.MAX_LISTS_REACHED : undefined}
        labels={{
          modal: {
            title: t('fa.mvAgent.addItemModal.title', { name: itemToAdd?.productName }),
            body: t('fa.mvAgent.addItemModal.body.text', { name: itemToAdd?.productName }),
          },
          footer: {
            createNewList: {
              label: t('fa.mvAgent.addItemModal.createNewListButton.label'),
            },
          },
          warning: {
            maxListsReached: {
              title: t('fa.inlineMessage.maxListLimit.title'),
              body: t('fa.inlineMessage.maxListLimit.body'),
              action: t('fa.inlineMessage.maxListLimit.buttonLabel'),
            },
          },
        }}
        onClose={() => {
          setShowAddItemToListModal(false);
          if (itemToRemove)
            removeFromFavouritesFail(canceledByUserError, {
              ...itemToRemove,
              skipToast: true,
            } as TRemoveFromFavouritesPayloadResponseError);
          else
            addToFavouritesFail(canceledByUserError, {
              ...itemToAdd,
              skipToast: true,
            } as TAddToFavouritesPayloadResponseError);
        }}
        onBack={
          itemToRemove
            ? () => {
                setShowAddOrRemoveModal(true);
                setShowAddItemToListModal(false);
              }
            : undefined
        }
        onSelect={async (selectedList) => addItemToList(selectedList)}
        onSelectCallback={(selectedList, listName, itemId) => {
          setShowAddItemToListModal(false);
          addToFavouritesSuccess({
            ...itemToAdd,
            listId: selectedList,
            itemId,
            listName,
          } as TAddToFavouritesPayloadResponse);
        }}
        onSelectFallback={(error: any) => {
          setShowAddItemToListModal(false);
          if (itemToRemove) {
            removeFromFavouritesFail(error, itemToRemove as TRemoveFromFavouritesPayloadResponseError);
          } else {
            addToFavouritesFail(error, itemToAdd as TAddToFavouritesPayloadResponseError);
          }
          sendErrorEvent({ eventName: EventName.ADD_TO_WISHLIST, error });
        }}
        onCreateNewList={() => {
          setShowCreateListModal(true);
          setShowAddItemToListModal(false);
        }}
        onGoToFavourites={() => {
          goToFavourites();
        }}
      />
      <CreateListAndAddToFavouritesModal
        isOpen={showCreateListModal}
        labels={{
          modal: {
            title: t('fa.mvAgent.createNewListModal.title'),
            body: t('fa.mvAgent.createNewListModal.body.text'),
          },
          footer: {
            save: {
              label: t('fa.mvAgent.createNewListModal.saveButton.label'),
            },
          },
          fields: {
            newListName: {
              label: t('fa.mvAgent.createNewListModal.inputField.label'),
              error: {
                required: t('fa.lists.createNewList.input.error.required'),
                maxLength: t('fa.lists.createNewList.input.error.maxLength'),
              },
            },
          },
          error: {
            tooManyFavouritesLists: {
              title: t('fa.list.listProduct.modal.move.modal.createList.error.tooManyFavouritesLists.title'),
              body: t('fa.list.listProduct.modal.move.modal.createList.error.tooManyFavouritesLists.body'),
            },
            default: {
              title: t('fa.mvAgent.createNewListModal.genericError.title'),
              body: t('fa.mvAgent.createNewListModal.genericError.body.text'),
            },
          },
        }}
        onClose={() => {
          setShowCreateListModal(false);
          if (itemToRemove)
            removeFromFavouritesFail(canceledByUserError, {
              ...itemToRemove,
              skipToast: true,
            } as TRemoveFromFavouritesPayloadResponseError);
          else
            addToFavouritesFail(canceledByUserError, {
              ...itemToAdd,
              skipToast: true,
            } as TAddToFavouritesPayloadResponseError);
        }}
        onBack={() => {
          setShowAddItemToListModal(true);
          setShowCreateListModal(false);
        }}
        onSave={async (newListName) => {
          const newListResponse = await createList(newListName);
          sendCreateListEvent(itemToRemove ?? itemToAdd, newListResponse.results.id);
          return newListResponse.results;
        }}
        onSaveFail={(error: any) => {
          sendErrorEvent({ eventName: EventName.CREATE_LIST, error });
        }}
        onAddItemToFavourites={async (selectedList) => addItemToList(selectedList)}
        onAddItemToFavouritesCallback={(listId, listName, itemId) => {
          setShowCreateListModal(false);
          addToFavouritesSuccess({ ...itemToAdd, listId, listName, itemId } as TAddToFavouritesPayloadResponse);
        }}
        onAddItemToFavouritesFallback={(newListId, newListName, error) => {
          setShowAddItemToListModal(false);
          addToFavouritesFail(error, {
            ...itemToAdd,
            listId: newListId,
            listName: newListName,
          } as TAddToFavouritesPayloadResponseError);
          sendErrorEvent({ eventName: EventName.ADD_TO_WISHLIST, error });
        }}
      />
      <AddOrRemoveItem
        isOpen={showAddOrRemoveModal}
        labels={{
          modal: {
            title: itemToRemove ? itemToRemove.productName : '',
            body: t('fa.mvAgent.addOrRemoveModal.body.text', { name: itemToRemove?.productName }),
          },
          footer: {
            goToAddItem: {
              label: t('fa.mvAgent.addOrRemoveModal.saveToListBtn.label'),
            },
            goToRemoveItem: {
              label: t('fa.mvAgent.addOrRemoveModal.removeFromListBtn.label'),
            },
          },
        }}
        onClose={() => {
          setShowAddOrRemoveModal(false);
          removeFromFavouritesFail(canceledByUserError, {
            ...itemToRemove,
            skipToast: true,
          } as TRemoveFromFavouritesPayloadResponseError);
        }}
        onAdd={() => {
          setShowAddItemToListModal(true);
          setShowAddOrRemoveModal(false);
        }}
        onRemove={onRemoveItem}
      />
      <RemoveFromFavouritesModal
        isOpen={showRemoveItemModal}
        lists={getMappedLists(listsToRemoveItem as ListToMap[])}
        labels={{
          modal: {
            title: t('fa.mvAgent.removeItemModal.title', { name: itemToRemove?.productName }),
            body: t('fa.mvAgent.removeItemModal.body.text', { name: itemToRemove?.productName }),
          },
          footer: {
            remove: {
              label: t('fa.mvAgent.removeItemModal.removeBtn.label'),
            },
          },
        }}
        onClose={() => {
          setShowRemoveItemModal(false);
          removeFromFavouritesFail(canceledByUserError, {
            ...itemToRemove,
            skipToast: true,
          } as TRemoveFromFavouritesPayloadResponseError);
        }}
        onBack={() => {
          setShowAddOrRemoveModal(true);
          setShowRemoveItemModal(false);
        }}
        onRemove={async (lists) => {
          const removeCalls = listsToRemoveItem
            .filter((list) => lists.includes(list.id))
            .flatMap((list) => list.itemIds.map((itemId) => [list.id, itemId]));
          await Promise.all(removeCalls.map(async ([listId, itemId]) => deleteItemFromList(listId, itemId)));
        }}
        onRemoveCallback={(lists, listName) => {
          setShowRemoveItemModal(false);
          removeFromFavouritesSuccess({
            ...itemToRemove,
            listIdsRemoveItem: lists,
            keepIcon: listsToRemoveItem.length !== lists.length,
            ...(listName && { listName }),
          } as TRemoveFromFavouritesPayloadResponse);
        }}
        onRemoveFallback={(error: any) => {
          setShowRemoveItemModal(false);
          sendErrorEvent({ eventName: EventName.REMOVE_FROM_WISHLIST, error });
          removeFromFavouritesFail(error, itemToRemove as TRemoveFromFavouritesPayloadResponseError);
        }}
      />
      <RemovedListsModal
        isOpen={showRemovedListsModal && showFavouritesMergeExceededMaxAmountModal}
        lists={removedLists}
        labels={{
          modal: {
            title: t('fa.prompt.maxListLimit.heading'),
            subtitle: t('fa.prompt.maxListLimit.subHeading'),
            body: t('fa.prompt.maxListLimit.body'),
          },
          footer: {
            close: {
              label: t('fa.prompt.maxListLimit.buttonLabel'),
            },
          },
        }}
        onClose={() => {
          setShowRemovedListsModal(false);
        }}
      />
    </>
  );
};
