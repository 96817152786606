export const backendUrl = ((port?: number) => {
  const portRegex = /:\d{4}/;
  const [retailUnit, language] = window.location.pathname.replace(/(^\/|\/$)/g, '').split('/');

  let origin = window.location.origin;

  if (port && portRegex.exec(origin)) {
    origin = origin.replace(portRegex, ':' + port);
  }

  return `${origin}/${retailUnit}/${language}`;
})();
